
import Vue, { PropType } from 'vue';
import QuizResultDetails from '@/components/quiz/QuizResultDetails';
import { IBreadcrumb, PlayerSessionId, UUID } from '@/domains/common';
import { IAtom } from '@/domains/atom';
import {
  IQuiz, IQuizQuestionResult, Quiz, QuizAnswersDisplayType, toQuizResult,
} from '@/domains/quiz';
import * as LxpQuizTestService from '@/services/api/lxp-quiz-test';
import TTBackButton from '@/components/ui/TTBackButton.vue';

interface IQuizAnswersData {
  isLoading: boolean;
  quiz: IQuiz;
  questions: IQuizQuestionResult[];
}

export default Vue.extend({
  name: 'QuizAnswers',

  components: {
    QuizResultDetails,
    TTBackButton,
  },

  inject: ['Names'],

  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      // @ts-ignore
      await vm.fetchQuizDetails();

      const {
        // @ts-ignore
        playerSessionId,
        // @ts-ignore
        stepId,
        // @ts-ignore
        trackSessionId,
        // @ts-ignore
        assignmentId,
      } = vm;

      // @ts-ignore
      if (vm.answersDisplayType === QuizAnswersDisplayType.NONE) {
        this.$router.push(
          toQuizResult({
            playerSessionId,
            stepId,
            trackSessionId,
            assignmentId,
          }),
        );
      }
    });
  },

  props: {
    track: {
      type: Object as PropType<IAtom>,
      required: true,
    },
  },

  data(): IQuizAnswersData {
    return {
      isLoading: false,
      quiz: new Quiz(),
      questions: [],
    };
  },

  computed: {
    breadcrumbs(): IBreadcrumb[] {
      return [
        {
          text: this.$t('LearningTracks.title'),
          to: {
            name: this.Names.R_APP_LEARNING_TRACKS,
          },
          'data-test-label': 'my-learning',
        },
        {
          text: this.track.name ?? '',
          to: {
            name: this.Names.R_APP_LEARNING_TRACK,
          },
          'data-test-label': 'track-name',
        },
        {
          text: this.$t('LearningTracks.materials'),
          disabled: true,
          'data-test-label': 'track-materials',
        },
      ];
    },

    playerSessionId(): PlayerSessionId {
      return Number(this.$route.params.playerSessionId);
    },

    answersDisplayType(): QuizAnswersDisplayType {
      return this.quiz.results?.answersDisplayType ?? QuizAnswersDisplayType.NONE;
    },

    stepId(): UUID {
      return this.$route.params.stepId;
    },

    trackSessionId(): string {
      return this.$route.params.trackSessionId;
    },

    assignmentId(): string {
      return this.$route.params.assignmentId;
    },
  },

  methods: {
    async fetchQuizDetails() {
      const { playerSessionId } = this;

      const params = {
        playerSessionId,
      };

      try {
        this.isLoading = true;
        const quiz = await LxpQuizTestService.testInfoGet(params);

        this.quiz = quiz;
        this.isLoading = false;
      } catch (e: any) {
        this.$di.notify.error({ content: e.message });
        await this.onErrorRedirect();
      }
    },

    async onErrorRedirect() {
      await this.$router.push(toQuizResult());
    },

    goBack() {
      this.$router.push({ name: this.Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_QUIZ_RESULT });
    },
  },
});
